<template>
  <div  class="ma-md-5 mb-16 px-0 " >
    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/menu" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="text-h6 font-weight-bold my-6">个人资料</div>
    </div>

    <v-card class="d-flex item-header pa-4 justify-space-between" flat tile>
      <div class="">用户ID:  {{userinfo.uid}}</div>
      <div class="">注册时间: {{userinfo.reg_time_str}}</div>
    </v-card>

    <v-card class="d-flex pa-md-4" flat tile>
      <v-row>
        <v-col class="d-flex justify-center mt-2" cols="12" md="3">
          <v-img :src="head_img" max-height="200" max-width="200" @click="showuser"></v-img>
        </v-col>
        <v-col class="px-md-12" cols="12" md="9">
          <div class="text-h6 font-weight-bold ma-2">{{userinfo.user_name}}</div>
          <div class="text-body-1 d-flex justify-space-between ma-2">
            <div>会员身份: </div>
            <div>{{userinfo.member_level==3?"VIP会员":"会员"}}</div>
          </div>
          <div class="text-body-1 d-flex justify-space-between ma-2" v-if="userinfo.member_level==3">
            <div>VIP到期: </div>
            <div>{{userinfo.vip_end_time_str}}</div>
          </div>
          <div class="text-body-1  d-flex justify-space-between ma-2">
            <div>手机: </div>
            <div>{{userinfo.user_tel?userinfo.user_tel:'-'}}</div>
          </div>
          <div class="text-body-1  d-flex justify-space-between ma-2">
            <div>昵称: </div>
            <div>{{userinfo.nick_name}}</div>
          </div>
          <div class="text-body-1 d-flex justify-space-between ma-2">
            <div>邮箱： </div>
            <div>{{userinfo.user_email?userinfo.user_email:'-'}}</div>
          </div>
          <div class="text-body-1  d-flex justify-space-between ma-2">
            <div>生日:</div>
            <div> {{userinfo.birthday?userinfo.birthday:'-'}}</div>
          </div>
          <div class="text-body-1 d-flex justify-space-between ma-2">
            <div>上次登录时间:</div> 
            <div>{{userinfo.last_login_time_str}}</div>
          </div>
          <div class="text-body-1 d-flex justify-space-between ma-2">
            <div>上次登录IP:</div>
            <div> {{userinfo.last_login_ip}}</div>
          </div>
          
        </v-col>
      </v-row>
    </v-card>


  </div>
</template>

<script>


import { mapState } from 'vuex'
import headimg from '../assets/headimg_null.png'


export default {


  data: () => ({
    itemid : 0,
    info : {},
    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.imgdomain,
      userinfo: state => state.user,
    }),
    head_img : function() {
      if (this.userinfo && this.userinfo.user_headimg)
      {
        return this.domain+this.userinfo.user_headimg
      }
      else
      {
        return  headimg
      }
    }
  },
  created() {

    
    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    showuser() {
      console.log(this.userinfo)
    }
  },
};
</script>
<style scoped>

.item-header {
  background-color: black;
  color:#ffffff;
  
  width:100%;
}
</style>

